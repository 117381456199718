<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              Export data
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
              :disabled="!selectedMeasurementFields.length && !selectedReportFields.length"
              text
              @click="resetSelection"
            >
              <v-icon
                left
              >
                mdi-reset
              </v-icon>

              Reset
            </v-btn>

            <v-btn
              :loading="exporting"
              :disabled="!canExport"
              text
              color="primary"
              @click="exportValues"
            >
              <v-icon
                left
              >
                mdi-download-outline
              </v-icon>

              Export to Excel
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  v-model="facilityId"
                  :items="$store.state.filteredFacilities"
                  item-text="name"
                  item-value="id"
                  label="Select ship"
                  prepend-icon="mdi-ferry"
                  clearable
                  @input="onFacilityChange"
                />
              </v-col>
              <v-col>
                <v-menu
                  ref="datepickerMenu"
                  v-model="periodMenu"
                  :close-on-click="false"
                  :close-on-content-click="false"
                  :return-value.sync="period"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ attrs, on }">
                    <v-text-field
                      v-model="periodText"
                      v-bind="attrs"
                      v-on="on"
                      label="Period"
                      prepend-icon="mdi-calendar"
                      readonly
                    />
                  </template>

                  <v-date-picker
                    v-model="period"
                    no-title
                    range
                    show-adjacent-months
                    scrollable
                  >
                    <v-spacer></v-spacer>

                    <v-btn
                      text
                      @click="periodMenu = false"
                    >
                      Cancel
                    </v-btn>

                    <v-btn
                      text
                      color="primary"
                      @click="$refs.datepickerMenu.save(period)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card flat>
                  <v-card-title>
                    Report fields
                  </v-card-title>
                  <v-treeview
                    v-model="selectedReportFields"
                    :items="reports"
                    item-children="reportFields"
                    selectable
                    selection-type="leaf"
                    return-object
                    @input="onSelectNode"
                  >
                    <template #append="{ item }">
                      <div class="text-caption">
                        {{ item.target }}
                      </div>
                    </template>
                  </v-treeview>
                </v-card>
              </v-col>

              <v-col>
                <v-card flat>
                  <v-card-title>
                    Measurement fields
                  </v-card-title>
                  <v-data-table
                    v-model="selectedMeasurementFields"
                    :headers="measurementHeaders"
                    :items="measurementFields"
                    :items-per-page="-1"
                    hide-default-footer
                    show-select
                  >
                    <template #[`item.status`]="{ item }">
                      <v-chip :color="getStatusColor(item.status)" label small>
                        {{ item.status }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ExportData',
  data() {
    return {
      loading: false,
      exporting: false,
      facilityId: '',
      facilityMeasurementFields: null,
      period: [],
      periodMenu: false,
      reports: [],
      reportFieldHeaders: [
        { text: 'Report field', value: 'name' },
        { text: 'Report', value: 'report.name' },
        { text: 'Target', value: 'report.target' }
      ],
      selectedMeasurementFields: [],
      selectedReportFields: []
    }
  },
  computed: {
    measurementHeaders() {
      const headers = [
        { text: 'Name', value: 'name' }
      ];

      if (this.facilityId) {
        headers.push({ text: 'Status', value: 'status', align: 'center', sortable: false });
      }

      return headers;
    },
    periodText() {
      return this.period.map(date => moment(date).format('YYYY.MM.DD')).join('-');
    },
    reportFields() {
      let result = [];

      this.reports.forEach(report => {
         report.fields.forEach(field => {
          result.push({
            ...field,
            report,
          });
        });
      });

      return result;
    },

    canExport() {
      return !!this.facilityId && this.period.length === 2 && (!!this.selectedMeasurementFields.length || !!this.selectedReportFields.length);
    },
    measurementFields: {
      get() {
        return this.facilityMeasurementFields ?? this.$store.getters.measurementFields;
      },
      set(value) {
        this.facilityMeasurementFields = value;
      }
    }
  },
  mounted: async function() {
    // Read query
    const { facilityId, reportCode } = this.$route.query;

    if (facilityId) {
      this.facilityId = facilityId;
    }

    this.period = [
      moment.utc(this.$store.state.period.from).startOf('day').format('YYYY-MM-DD'),
      moment.utc(this.$store.state.period.to).endOf('day').format('YYYY-MM-DD')
    ]

    await this.fetchData();

    if (reportCode) {
      this.loadSelectionFromQuery(reportCode);
    }
  },
  methods: {
    exportValues: async function () {
      try {
        this.exporting = true;

        const data = {
          facilityId: this.facilityId,
          from: moment.utc(this.period[0]).startOf('day').toISOString(),
          to: moment.utc(this.period[1]).endOf('day').toISOString(),
          measurementFieldIds: this.selectedMeasurementFields.map(x => x.id),
          reportFieldIds: this.selectedReportFields.map(x => x.id)
        };

        const filename = this.generateFilename();

        await this.$Services.export.get(data, filename);
      } catch (error) {
        this.$store.dispatch('showDefaultError');
        console.error(error);
      } finally {
        this.exporting = false;
      }
    },

    fetchData: async function () {
      this.loading = true;

      if (this.facilityId) {
        this.measurementFields = await this.$store.dispatch('getMeasurementFieldsByFacilityId', this.facilityId);
      }
    
      this.reports = await this.$store.dispatch('getReports');
    
      this.loading = false;
    },

    onFacilityChange: async function () {
      if (!this.facilityId) {
        this.facilityMeasurementFields = null;
      }

      this.fetchData();
    },

    onSelectNode: function (reportFields) {
      // Run through the nodes and update measurement fields
      const ids = reportFields.map(x => x.measurementFieldIds).flat();
      const distinctIds = [...new Set(ids.concat(this.selectedMeasurementFields))];

      this.selectedMeasurementFields = this.measurementFields.filter(x => distinctIds.includes(x.id));
    },

    loadSelectionFromQuery: function (code) {
      if (!this.reports.some(x => x.code === code)) {
        console.error('Code not found in reports');
        this.$store.dispatch('showDefaultError');
        return;
      }

      const report = this.reports.find(report => report.code === code);

      this.selectedReportFields.push(...report.reportFields);
    },

    generateFilename: function () {
      const facility = this.$store.getters.facilityById(this.facilityId);

      return facility.name.replace(' ', '-').toLowerCase() + '_' + this.periodText + '.xlsx';
    },

    resetSelection: function () {
      this.selectedReportFields = [];
      this.selectedMeasurementFields = [];
    },

    getStatusColor: function (status) {
      switch (status) {
        case 'Waiting':
          return 'grey lighten-3';
        case 'In progress':
          return 'secondary';
        case 'Approved':
          return 'primary';
        case 'Active':
          return 'success';
        default:
          return '';
      }
    }
  },
}
</script>